<template>
  <b-card
    v-if="hasItems"
    :title="title"
  >
    <touch-button-group
      v-if="isItemOptions"
      class="w-100 mb-2 mt-2 d-flex"
    >
      <touch-button
        v-for="item in items"
        :key="item.id"
        class="flex-fill"
        :class="{'active' : selectedItemOption === item.id}"
        ingroup
        outline
        thinkiness
        size="sm"
        @action="selectedItemOption = item.id"
      >
        {{ item.name }}
      </touch-button>
    </touch-button-group>

    <vue-apex-charts
      v-if="canDisplay && isItemOptions && selectedItemOption !== null"
      type="pie"
      :height="height"
      class="mt-2 mb-1"
      :series="series"
      :options="options"
    />

    <div class="w-100">
      <template v-if="canDisplay">
        <div
          v-for="(data,index) in display"
          :key="index"
          class="d-flex justify-content-between mt-50"
        >
          <div class="series-info">
            <feather-icon
              icon="CircleIcon"
              size="16"
              class="mr-50"
              stroke-width="0.32em"
              :style="{color : data.color}"
            />
            <span>{{ data.label }}</span>
          </div>
          <span class="font-weight-bold">
            {{ numberFormat(data.percent) }}
            <span class="text-muted">%</span>
          </span>
        </div>
      </template>

      <template v-else>
        <p class="text-muted text-center">
          {{ $t('therms.no-data-to-display') }}
        </p>
      </template>
    </div>
  </b-card>

</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from '@core/components/apex-chart/index.vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import TouchButtonGroup from '@core/components/touch-button/TouchButtonGroup.vue'

/**
 * items : {
 *   icon :
 *   color :
 *   label :
 *   percent :
 * }
 */
export default {
  components: {
    BCard,
    VueApexCharts,
    TouchButton,
    TouchButtonGroup,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    height: {
      type: Number,
      default: 300,
    },
    items: {
      required: true,
      type: [Array, Object],
    },
    isItemOptions: {
      type: Boolean,
      default: false,
    },
    customOptions: {
      default() {
        return {}
      },
      type: Object,
    },
  },
  data() {
    return {
      selectedItemOption: null,
    }
  },
  computed: {
    hasItems() {
      return (this.isItemOptions ? this.items.length >= 1 : this.items.length >= 1)
    },
    canDisplay() {
      return (this.isItemOptions ? this.items.filter(a => a.items.filter(e => e.percent > 0).length >= 1).length >= 1 : this.items.filter(e => e.percent > 0).length >= 1)
    },
    /**
     * @returns {Array<String>}
     */
    series() {
      if (this.selectedItemOption === null) return null
      if (this.isItemOptions) {
        // eslint-disable-next-line radix
        return this.items.filter(e => String(e.id) === String(this.selectedItemOption))[0]
          .items
          .map(e => e.percent)
      }

      return this.items.map(e => e.percent)
    },
    /**
     * @returns {Array<String>}
     */
    labels() {
      if (this.selectedItemOption === null) return null
      if (this.isItemOptions) {
        // eslint-disable-next-line radix
        return this.items.filter(e => String(e.id) === String(this.selectedItemOption))[0]
          .items
          .map(e => e.label)
      }

      return this.items.map(e => e.label)
    },
    /**
     * @returns {Array<String>}
     */
    colors() {
      if (this.selectedItemOption === null) return null
      if (this.isItemOptions) {
        // eslint-disable-next-line radix
        return this.items.filter(e => String(e.id) === String(this.selectedItemOption))[0]
          .items
          .map(e => e.color)
      }

      return this.items.map(e => e.color)
    },
    display() {
      if (this.selectedItemOption === null) return null
      if (this.isItemOptions) {
        return this.items
        // eslint-disable-next-line radix
          .filter(e => String(e.id) === String(this.selectedItemOption))[0]
          .items
      }

      return this.items
    },
    /**
     * @returns {{dataLabels: {enabled: boolean}, legend: {show: boolean}, chart: {toolbar: {show: boolean}}, stroke: {width: number}, colors: Array<String>, labels: Array<String>}}
     */
    options() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        labels: this.labels,
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${window.numberFormat(val)} %`
          },
        },
        legend: { show: false },
        stroke: {
          width: 4,
        },
        colors: this.colors,
        ...this.customOptions,
      }
    },
  },
  mounted() {
    if (this.isItemOptions && this.hasItems) {
      this.selectedItemOption = this.items[0]?.id
    }
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>
